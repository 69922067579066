import React from "react";
import { Link } from "gatsby";
import SEO from "../../components/Seo";
import Layout from "../../components/Layout";
import { useAlternateLangs } from "../../components/Hreflangs";
import MainContent from "../../components/MainContent";
import Breadcrumb from "../../components/Breadcrumb";

import H from "../../components/Headline";

const breadCrumbLevels = {
  Home: "/",
  // Blog: "/blog",
  Imprint: "/da/juridisk-information"
};

// Hreflang data
const alternateLangs = useAlternateLangs(
  // replace with pages x-default
  "/en/imprint"
);

const Imprint = props => (
  <Layout location={props.location} alternateLangs={alternateLangs}>

    <React.Fragment>
      <SEO
        title="Aftryk"
        description="Information om operatøren af webstedet samt juridiske meddelelser."
        lang="da"
        alternateLangs={alternateLangs}
        meta={[{ name: "robots", content: "noindex" }]}
      />
      <MainContent article={false}>
        <Breadcrumb breadCrumbLevels={breadCrumbLevels} />

        <H as="h1">Aftryk</H>
        <p>Operatør af webstedet bluerivermountains.com:</p>
        <p>
          Matthias Kupperschmidt
          <br />
          Frederikssundsvej 22
          <br />
          2400 København
          <br />
          Danmark
        </p>
        <p className="baseline">
          Email: <a href="mailto:info@bluerivermountains.com">info@bluerivermountains.com</a>
          <br />
          Telefon: +45 93 86 85 64
        </p>
        <p className="baseline">Skatteidentifikationsnummer: 39474263</p>
        <p>Denne side er copyright af Matthias Kupperschmidt.</p>
        <H as="h2">Juridisk</H>
        <p>
          Jeg gennemgår regelmæssigt oplysningerne på dette websted. På trods af al omhu kan dataene have ændret sig i
          mellemtiden. Et ansvar eller en garanti for aktualiteten, korrektheden og fuldstændigheden af informationerne kan
          derfor ikke accepteres. Det samme gælder for websteder, der henvises til via hyperlinks. Matthias
          Kupperschmidt er ikke ansvarlig for indholdet af de websteder, der nås som følge af en sådan
          forbindelse. Reproduktion af information eller data – især brugen af tekster, dele af tekster eller billeder –
          kræver forudgående samtykke. Dette gælder også for alle blogindlæg. Jeg påpeger, at jeg gemmer alle data indtastet
          i søgemasken og ikke bruger disse data til egen reklame eller videregiver dem til tredjepart.
        </p>
        <p>
          Yderligere oplysninger kan findes i min <Link to="/da/privatlivspolitik">privatlivspolitik</Link>.
        </p>
      </MainContent>
    </React.Fragment>

  </Layout>
);
export default Imprint;